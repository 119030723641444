body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background: #1b2534;
  color: #ffffff;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

span .MuiFormControlLabel-label .Mui-disabled {
  color: red;
}

* {
  margin: 0;
  padding: 0;
}

#loader {
  position: relative;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 4px solid #1b2534;
  overflow: hidden;
  box-shadow: 7px 7px 15px #141b26, -7px -7px 15px #222f42;
}

#loader:before {
  content: "Searching...";
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25px;
  left: 25px;
  right: 25px;
  bottom: 25px;
  z-index: 10;
  background: #1b2534;
  border-radius: 50%;
  box-shadow: inset 7px 7px 15px #141b26, inset -7px -7px 15px #222f42;
}

#loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
  background: linear-gradient(#14ffe9, #393b3d, #233249);
  filter: blur(20px);
  animation: animate 0.5s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.citation {
  margin: 10px 0;
  padding: 10px;
}

.blink {
  animation: blink-animation 0.32s steps(20, start) 1;
}

@keyframes blink-animation {
  to {
    filter: brightness(2);
  }
}
